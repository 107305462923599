<template>
  <div>
    <el-table v-loading="loading" :data="data.data">
      <el-table-column>
        <template slot="header">
          <span>Order Id</span>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'orders.order',
              params: { id: scope.row.id },
            }"
          >
            <span class="font-sm text-primary">
              {{ scope.row.id }}</span
            ></router-link
          >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span>Customer</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm"> {{ formatName(scope.row.customer) }}</span>
        </template>
      </el-table-column>
      <template v-if="service === 'beauty'">
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Type</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatToTitleCase(scope.row.type) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Variations</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span
              class="font-sm text-cursor text-primary"
              @click="setSpecifics(scope.row)"
            >
              {{ scope.row.variations.length }}</span
            >
          </template>
        </el-table-column>
      </template>

      <el-table-column v-else-if="service === 'cleaning'">
        <template slot="header">
          <div class="table--header">
            <span>Type</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm"> {{ formatToTitleCase(scope.row.type) }}</span>
        </template>
      </el-table-column>
      <template v-else-if="service === 'laundry'">
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Type</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatToTitleCase(scope.row.type) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div class="table--header">
              <span>Quantity</span>
            </div>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatServiceUnit("laundry", scope.row.bags, true)
            }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-else-if="service === 'meal'" width="100">
        <template slot="header">
          <div class="table--header">
            <span>Meals</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span
            class="font-sm text-cursor text-primary"
            @click="setSpecifics(scope.row)"
          >
            {{ scope.row.meals.length }}</span
          >
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tag :type="scope.row.confirmed ? 'success' : 'warning'">{{
            scope.row.confirmed ? "Confirmed" : "Edited"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="service === 'laundry'">
        <template slot="header">
          <div class="table--header">
            <span>Pickup Date</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatDate(scope.row.pickup, "do m, y") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>{{ deliveryColumn }}</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatDate(scope.row.delivery, "ddd do, m y") }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <eden-pagination
      :from="from"
      :to="to"
      :total="total"
      :current-page.sync="page"
    />
    <order-specifics
      :show.sync="order.show"
      :order="order.content"
      :service="service"
    />
  </div>
</template>

<script>
import OrderSpecifics from "@/components/Gardeners/Gardener/ProvisionedOrders/OrderSpecifics";
export default {
  name: "OrdersView",
  components: { OrderSpecifics },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      page: 1,
      pageSize: 50,
      order: {
        show: false,
        content: {},
      },
    };
  },
  computed: {
    paginateData() {
      if (!this.orders.length) {
        return [];
      }
      const sorted = this.sortArray({
        data: this.orders,
        property: "delivery",
      });
      return this.paginate(sorted, this.pageSize);
    },
    data() {
      return this.paginateData[this.page] || [];
    },
    from() {
      return this.data.from;
    },
    to() {
      return this.data.to;
    },
    total() {
      return this.orders.length;
    },
    deliveryColumn() {
      switch (this.service) {
        case "beauty":
          return "SP Arrival Date";
        case "cleaning":
          return "Cleaning Date";
        case "laundry":
          return "Delivery Date";
        case "meal":
          return "Delivery Date";
        default:
          return "Delivery Date";
      }
    },
  },
  methods: {
    setSpecifics(order) {
      this.order.content = order;
      this.order.show = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
