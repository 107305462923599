<template>
  <el-collapse v-model="customer" accordion class="sp-view--collapse">
    <template v-for="(value, key, index) in sortedOrders">
      <el-collapse-item v-if="value.orders.length" :key="index" :name="key">
        <template slot="title">
          <div class="title">
            <div class="name">
              <i
                :class="`el-icon-caret-${
                  customer === key ? 'bottom' : 'right'
                }`"
              />
              <span>{{ formatName(key) }}</span>
            </div>
            <div>
              <el-tag type="success">{{ value.confirmed }} confirmed</el-tag>
              <el-tag type="warning">{{ value.edited }} edited</el-tag>
            </div>
          </div>
        </template>
        <orders-view :service="service" :orders="value.orders" />
      </el-collapse-item>
    </template>
  </el-collapse>
</template>

<script>
import OrdersView from "./OrdersView";

export default {
  name: "CustomerView",
  components: { OrdersView },
  props: {
    service: {
      type: String,
      default: "",
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      customer: "",
    };
  },
  computed: {
    sortedOrders() {
      let customers = {};
      this.orders.forEach((order) => {
        if (!customers[order.customer]) {
          customers[order.customer] = {
            confirmed: 0,
            edited: 0,
            orders: [],
          };
        }

        order.confirmed
          ? customers[order.customer].confirmed++
          : customers[order.customer].edited++;
        customers[order.customer].orders.push(order);
      });

      return customers;
    },
  },
  methods: {
    setSelections(orders) {
      this.setSelectedOrders = orders.map((order) => order.id);
    },
  },
};
</script>

<style lang="scss">
.sp-view--collapse {
  .el-collapse-item {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--eden-grey-septenary);
    padding: 16px 25px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        i {
          margin-right: 10px;
        }

        span {
          color: var(--eden-grey-primary);
        }
      }
    }

    .el-collapse-item__arrow {
      display: none;
    }

    .el-collapse-item__wrap {
      margin-top: 20px;
    }
  }
}
</style>
