<template>
  <el-dialog :visible.sync="setShow" width="30%">
    <template slot="title">
      <div class="title">
        <p>{{ formatName(order.customer) }}</p>
        <router-link
          class="text-primary"
          :to="{ name: 'orders.order', params: { id: order.id } }"
          >({{ order.id }})</router-link
        >
      </div>
    </template>
    <ul v-if="service === 'beauty'">
      <li v-for="(item, i) in order.variations" :key="i">
        <p>{{ formatToTitleCase(item.variation) }}</p>
        <span class="mb-10">[{{ item.addons }}]</span>
        <span>{{ item.sp }}</span>
      </li>
    </ul>
    <ul v-if="service === 'meal'">
      <li v-for="(meal, i) in order.meals" :key="i">
        <p>{{ formatMealName(meal.item) }}</p>
        <span>{{ meal.sp }}</span>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  name: "OrderSpecifics",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    service: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  mounted() {},
  methods: {
    closeEvent() {
      this.setShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 40px;

  p {
    margin-right: 5px;
    font-size: 0.875rem;
    font-weight: 500;
  }

  a {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

ul {
  margin: 0;
  padding: 0 20px;

  li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    p {
      font-weight: 500;
    }

    span {
      font-size: 0.825rem;
      color: var(--eden-grey-tertiary);
      display: block;
    }
  }
}
</style>
